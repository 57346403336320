module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140065658-1","head":false,"anonymize":true,"respectDNT":true,"defer":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"orgsite","omitPrismicScript":true,"pages":[{"extraPageFields":"list","type":"Person","match":"/who-we-are/:uid","path":"/team","component":"/opt/build/repo/src/templates/bio.js"},{"type":"Case_study","match":"/our-work/:uid","path":"/casestudies","component":"/opt/build/repo/src/templates/caseStudy.js"},{"type":"Basic_page","match":"/:uid","path":"/basic","component":"/opt/build/repo/src/templates/basicPage.js"},{"type":"Blog_post","match":"/insights/:uid","path":"/insights","sortBy":"date_posted_DESC","component":"/opt/build/repo/src/templates/blogPost.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Public Square","short_name":"Digital Public Square","start_url":"/","background_color":"#2B2B2A","theme_color":"#2B2B2A","display":"minimal-ui","icon":"src/images/dps-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"32fe13930e7ff0d571dae439cca3c23c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
