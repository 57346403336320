// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = function linkResolver(doc) {
  if (doc.type === "case_study") {
    return `/our-work/${doc.uid}`
  }

  if (doc.type === "blog_post") {
    return `/insights/${doc.uid}`
  }

  if (doc.type === "basic_page") {
    return `/${doc.uid}`
  }

  if (doc.type === "person") {
    return `/who-we-are/${doc.uid}`
  }

  if (doc.type === "homepage") {
    return "/"
  }

  if (doc.type === "about") {
    return "/who-we-are"
  }

  if (doc.type === "blog_list") {
    return "/insights"
  }

  if (doc.type === "approach") {
    return "/our-approach"
  }

  if (doc.type === "our_work") {
    return "/our-work"
  }

  if (doc.type === "support") {
    return "/support-us"
  }

  // Homepage route fallback
  return "/"
}
