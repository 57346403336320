/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require("react")
const {
  registerLinkResolver,
} = require("@prismicio/gatsby-source-prismic-graphql")
const { linkResolver } = require("./src/utils/linkResolver")
require("wicg-inert")
require("custom-event-polyfill")
require("element-closest/browser")
registerLinkResolver(linkResolver)

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

exports.wrapRootElement = props => {
  return <div>{props.element}</div>
}
